<template>
  <el-card :body-style="{padding:'0px'}">
    <el-form class="ep-search-panel" ref="searchForm" label-position="top">
      <!-- 普通查询部分 START -->
      <el-row class="m-common-field" :gutter="20">
        <el-col :span="6">
          <el-form-item>
            <template #label><span class="ep-search-panel-field-label">供应商</span></template>
            <el-select v-model="sup_shop_id" filterable placeholder="请选择"  style="width:100%" @change="onShopChange">
              <el-option v-for="item in supMerchantShopPairs" :key="item.sup_shop_id" :label="item.sup_shop_name" :value="item.sup_shop_id">
                <span class="ep-select-option-left">{{ item.sup_shop_name }}</span>
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item class="m-common">
            <template #label><span class="ep-search-panel-field-label">货品查询</span></template>
            <el-input placeholder="请输入查询内容" v-model="searchContent1" class="ep-search-form-input-with-select">
              <template #prepend>
                <el-select v-model="searchKey1" placeholder="请选择">
                  <el-option key="goods_item_code" label="货品编码" value="goods_item_code" />
                  <el-option key="goods_item_name" label="货品名称" value="goods_item_name" />
                </el-select>
              </template>
            </el-input>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item>
            <template #label><span class="ep-search-panel-field-label">货品类目</span></template>
            <el-select v-model="category_code" filterable placeholder="请选择" style="width:100%;">
              <el-option label="全部" value="__ALL__">全部</el-option>
              <el-option v-for="item in categoryCodePairs" :key="item.code" :label="item.name" :value="item.code">
                <span class="ep-select-option-left">{{ item.name }}</span>
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item>
            <template #label><span class="ep-search-panel-field-label">仓库查询</span></template>
            <el-select v-model="store_code" filterable placeholder="请选择"  style="width:100%">
              <el-option label="全部" value="__ALL__">全部</el-option>
              <!-- <el-option v-for="item in storePairs" :key="item.store_id" :label="item.store_name" :value="item.store_id">
                <span class="ep-select-option-left">[{{ item.store_code }}]{{ item.store_name }}</span>
              </el-option> -->
              <el-option v-for="item in storePairs" :key="item.store_id" :label="item.display_store_name" :value="item.store_id">
                <span class="ep-select-option-left">[{{ item.display_store_code }}]{{ item.display_store_name }}</span>
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        
        <!-- <el-col :span="6">
          <el-form-item>
            <template #label><span class="ep-search-panel-field-label">下单时间</span></template>
            <el-date-picker v-model="createdTime" type="datetimerange"
              :picker-options="pickerOptions" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" align="right">
            </el-date-picker>
          </el-form-item>
        </el-col> -->

        

      </el-row>
      <!-- 普通查询部分 END -->

      <!-- 高级查询部分 START -->
      <div class="m-advance-filed" v-show="this.advanceToggle">
        <!-- <div class="ep-search-form-item-row">
          <div class="ep-search-form-item-col1">
            <el-form-item label="商户编码：">
              <el-input v-model="searchModel.merchant_code" />
            </el-form-item>
          </div>
          <div class="ep-search-form-item-col1">
            <el-form-item label="商户编码：">
              <el-input v-model="searchModel.merchant_code" />
            </el-form-item>
          </div>
          <div class="ep-search-form-item-col1">
            <el-form-item label="商户编码：">
              <el-input v-model="searchModel.merchant_code" />
            </el-form-item>
          </div>
        </div> -->
      </div>
      <!-- 高级查询部分 END -->

      <!-- 功能按键组 START -->
      <div>
        <el-button type="primary" @click="onSearchClick">查询</el-button>
        <el-button type="primary" plain @click="onResetClick">重置</el-button>
        <!-- <el-button type="primary" plain @click="onAdvanceToggle">高级查询</el-button> -->
        <span class="ep-form-button-separation" />
        

      </div>
      <!-- 功能按键组 END -->
  
    </el-form>
  </el-card>
</template>

<script>
import { ArrowDown } from '@element-plus/icons-vue'
import { CategoryCodeModel } from '@/model/goods/CategoryCodeModel.js'
import { apiV1GetSupMerchantShopPairs } from '@/api/fenxiao.js'
import { apiV1GetStorePairs } from '@/api/merchant.js'
import AuthUtils from '@/utils/auth-utils.js'

export default {
  name: "goods-search-panel",
  components: {
    ArrowDown,
  },
  props: [],
  emits: ['search', 'reset'],
  data() {
    return {
      supMerchantShopPairs: [],
      sup_shop_id: '',

      categoryCodePairs: [],
      category_code: '__ALL__',

      storePairs:[],
      store_code: '__ALL__',

      // 高级查询切换标记
      advanceToggle: false,
      // 查询字段 - 订单搜索
      searchKey1: 'goods_item_code',
      searchContent1: '',
 
    };
  },
  computed: {},
  watch: {},
  methods: {
    // 点击触发 - 查询
    onSearchClick() {
      if (this.sup_shop_id == '') {
        return false
      }
      var searchData = this.getSearchFilter()
      this.$emit('search', searchData)
    },
    // 点击触发 - 高级查询
    onAdvanceToggle() {
      this.advanceToggle = !this.advanceToggle
    },
    // 点击触发 - 重置
    onResetClick() {
      this.searchContent1 = ''
      // this.createdTime = []
      this.sup_shop_id = ''
      this.category_code = '__ALL__'
      this.store_code = '__ALL__'

      this.$emit('reset', {})
    },

    // 当供应商改变时触发
    onShopChange() {
      this.store_code = '__ALL__'
      this.getStorePairData()
    },
    
    // ================================================================================================
    // 获取查询filter
    getSearchFilter() {
      let searchFilter = {}
      if (this.sup_shop_id == '') {
        return false
      } else {
        searchFilter.sup_shop_id = this.sup_shop_id
      }
      if (this.searchKey1.trim() != '' && this.searchContent1.trim() != '') {
        searchFilter[this.searchKey1] = this.searchContent1
      }
      if (this.category_code != '__ALL__') {
        searchFilter.category_code = this.category_code
      }
      if (this.store_code != '__ALL__') {
        searchFilter.store_code = this.store_code
      }
      // if (this.createdTime != null && this.createdTime.length > 0) {
      //   var start_created = parseInt(this.createdTime[0].getTime() / 1000)
      //   var end_created = parseInt(this.createdTime[1].getTime() / 1000)

      //   searchFilter.order_time_start = start_created
      //   searchFilter.order_time_end = end_created
      // }

      return searchFilter
    },


    // 获取类目编码pair
    getCategoryCodeMPairData() {
      var vueThis = this
      if (this.categoryCodePairs.length == 0) {
        let promise2 = new Promise(function(resolve, reject) {
          vueThis.categoryCodePairs = CategoryCodeModel.getPairData(); resolve()
        });
      }
    },

    // 根据分销商户ID获取供应店铺pair
    getSupShopDataByMerchantId() {
      // console.log(AuthUtils.getCurrentShopId())
      // if (this.supMerchantShopPairs.length == 0) {
        apiV1GetSupMerchantShopPairs({fx_shop_id: AuthUtils.getCurrentShopId()}).then((res) => {
          this.supMerchantShopPairs = res.data.data;
        })
      // }
    },


    // 获取店铺仓库列表
    getStorePairData() {
      apiV1GetStorePairs({shop_id: this.sup_shop_id}).then((res) => {
        this.storePairs = res.data.data;
      })
    },
    
    
  },
  mounted() {
    this.$nextTick(() => {
      this.getSupShopDataByMerchantId()

      this.getCategoryCodeMPairData()
    })
  }
};
</script>

<style scoped>
</style>
