<template>
  <el-container class="ep-page-container">
    <el-header class="ep-page-header">
      <div class="ep-page-header-breadcrumb">
        <el-breadcrumb><el-breadcrumb-item>分销</el-breadcrumb-item><el-breadcrumb-item>分销货品</el-breadcrumb-item><el-breadcrumb-item>分销货品列表</el-breadcrumb-item></el-breadcrumb>
      </div>
      <div class="ep-page-header-filter"></div>
      <div class="ep-page-header-toolbar">
        <!-- <el-button type="primary" v-permission="'C0001'" @click="onSelectSupGoodsItemClick">添加分销货品</el-button> -->
        <!-- <el-button type="primary" v-if="$$auth.hasPermission('A0006')" @click="onExportCurrentShopGoodsClick">导出所有商品</el-button> -->
      </div>
    </el-header>

    <!-- 其他窗体 START -->
    <GoodsDetailView ref="refGoodsDetailView" @submit="onEditSubmit" />
    <!-- 其他窗体 END -->

    <el-main class="ep-page-main">
      <!-- 查询面板 START -->
      <GoodsSearchPanel ref="searchView" @search="onSearched" @reset="onSearchReset"/>
      <!-- 查询面板 END -->

      <!-- 数据表格 START -->
      <div class="el-card" style="margin:10px 0 80px 0;">
        <!-- 数据表格-过滤标签栏 START -->
        <el-tabs type="card" v-model="dataTableFilter" @tab-change="onDataTableTabChange">
          <el-tab-pane label="全部" name="__ALL__"></el-tab-pane>
          <!-- <el-tab-pane label="未审核" name="0"></el-tab-pane> -->
          <!-- <el-tab-pane label="已审核" name="1"></el-tab-pane> -->
        </el-tabs>
        <!-- 数据表格-过滤标签栏 END -->

        <!-- 数据表格 START -->
        <div class="ep-table-panel-wrap">
          <el-row :gutter="20">
            <el-col v-for="(item, index) in this.epDataList" :key="item.sup_goods_item_id" :span="4" :offset="(index%5 == 0) ? 2 : 0">
              <el-card :body-style="{ padding: '0px' }" shadow="hover" class="m-card">
                <el-image class="m-main-image" :src="checkImageUrl(item.main_image_url)" fit="contain">
                  <template #error><div class="image-slot"><el-icon><IconPicture/></el-icon></div></template>
                </el-image>
                <div style="padding: 14px">
                  <div class="ep-overflow-text">{{ item.goods_item_name }}</div>
                  <div class="ep-cell-subtitle">品牌：{{ item.brand_name }}</div>
                  <div class="m-card-bottom">
                    <div class="m"><div class="m-card-label">供货价</div><div class="m-card-price">¥ {{ $$utils.toFixed2(item.cost_price) }}</div></div>
                    <div class="m"><div class="m-card-label">零售价</div><div class="m-card-price">¥ {{ $$utils.toFixed2(item.retail_price) }}</div></div>
                  </div>
                  <div class="m-card-bottom">
                    <!-- <time class="time"> </time> -->
                    <div class="m"><span v-if="item.selected_state" class="m-select-text">已添加</span></div>
                    <div class="m"><el-button type="primary" class="button" @click="onGoodsDetailClick(item.sup_goods_item_id)">详情</el-button></div>
                    
                  </div>
                </div>
              </el-card>
            </el-col>
          </el-row>
  
        </div>
        <!-- 数据表格 END -->
      </div>
      <!-- 数据表格面板 END -->

      <!-- 数据页面固定footer START -->
      <div class="ep-float-footer">
        <div class="ep-float-footer-toolbar">
          <el-button plain class="ep-button-select-all" @click="$refs.epDataTable.toggleAllSelection()">
            <span class="ep-button-select-all-text1">已选中 <span class="ep-button-select-all-text2">{{ epMultipleSelection.length }}</span> / <span class="ep-button-select-all-text2">{{ epDataList.length }}</span> 项</span>
          </el-button>
          <el-button type="primary" :disabled="this.epDataList.length < 1" @click="onBatchSelectThisPageClick">添加本页货品</el-button>
          <!-- <el-button type="primary" :disabled="getMultipleSelectionCount() == 0" @click="onCancelClick">作废</el-button>
          <el-button type="primary" :disabled="getMultipleSelectionCount() == 0" @click="onUnancelClick">恢复</el-button> -->
          <!-- <el-button v-permission="['A0002']" type="primary" :disabled="getMultipleSelectionCount() == 0" @click="onExportYunsutongMaskClick">导出订单</el-button> -->
          <!-- <el-button v-permission="['A0003']" type="primary" :disabled="getMultipleSelectionCount() == 0" @click="onExportYunsutongClick">导出完整订单</el-button> -->

        </div>
        <div class="ep-float-footer-pager">
          <ep-pagination :ep-page="this.epPage" :ep-total="this.epTotal" :ep-page-size="this.epPageSize" @pagination-change="onPaginationChange"/>
        </div>
      </div>
      <!-- 数据页面固定footer END -->
    </el-main>

  </el-container>
</template>

<script>
import permission from '@/directive/PermissionDirective.js'
import DataTableMixin from '@/mixin/DataTableMixin.js'
import { Edit, Picture as IconPicture } from '@element-plus/icons-vue'
import SourceCodeTag from '@/components/v1/jxgocs/tags/SourceCodeTag'
import GoodsSearchPanel from '@/views/v1/fenxiao/goods/market/GoodsSearchPanel'
import GoodsDetailView from '@/views/v1/fenxiao/goods/market/GoodsDetailView'
import { apiV1GetSupGoodsItemPage, apiV1ActionBatchSelectSupGoodsItem } from '@/api/goods.js'
import AuthUtils from '@/utils/auth-utils.js'
import GlobalConfig from '@/config/index.js'

// import { Test1 } from '@/pages/test/Test1'

export default {
  mixins: [ DataTableMixin ],
  name: "goods-market-page",
  components: {
    Edit, IconPicture,
    SourceCodeTag,
    GoodsSearchPanel,
    GoodsDetailView,
    // SupGoodsItemSelectDialog,
  },
  directives: {
    permission
  },
  props: [],
  data() {
    return {
      shop_id: '',

      current_merchant_id: '',
      // 当前店铺id
      current_shop_id: '', // 32201908210407394928150064e55678

      activeName: 'first',
      epPageSize: 20,
      dataTableFilter: '__ALL__',

      main_image_url: '',
    }
  },
  computed: {},
  methods: {
    getFilter() {
      let data = {}
      if (AuthUtils.getCurrentShopId()) {
        data = {
          fx_shop_id: AuthUtils.getCurrentShopId(),
          goods_item_state: 1,
          is_show_selected_state : 1,
        }
      }
      return data
    },
    getPage({reload = false} = {}) {
      let paginationData = this.getPagination(reload)
      let filterData = this.getFilter()
      let searchData = this.$refs.searchView.getSearchFilter()

      if (searchData == false) { return; }
      let queryData = Object.assign(paginationData, filterData, searchData)

      this.epIsLoading = true
      apiV1GetSupGoodsItemPage(queryData).then((res) => {
        this.epDataList = res.data.data
        this.setPaginationTotal(res.data.pagination.total)
        this.epIsLoading = false
      })
    },

    // ===================================================================================================

    // 点击添加
    onAddClick() {
      let newUrl = this.$router.resolve({ path: `/v1/goods/goods/add` })
      window.open(newUrl.href, '_blank')
    },
    // 点击查看
    onDetailClick(id) {
      this.$router.push({ name: 'MerchantsDetailPage', params: { id: id }})
    },
    // 点击修改
    onUpdateClick(id) {
      let newUrl = this.$router.resolve({ path: `/v1/goods/goods/edit/${id}` })
      window.open(newUrl.href, '_blank')
    },
    // 点击删除
    onDeleteClick(id) {
      // this.resolveDeleteConfirm().then(() => {
      //   apiDeleteMerchants({
      //     merchant_id: id,
      //   }).then((res) => {
      //     this.getPage()
      //     this.$message.success("操作成功")
      //   })
      // }).catch(()=>{})
    },

    // 编辑提交
    onEditSubmit(result) {
      this.getPage()
      // this.$message.success("操作成功")
    },


    // 回调触发 - 查询
    onSearched(data) {
      this.getPage({reload: true})
    },
    // 回调触发 - 查询重置
    onSearchReset() {
      this.getPage({reload: true})
    },
    // Change触发 - 数据表Tab
    onDataTableTabChange(name) {
      this.getPage({reload: true})
    },


    // 货品详情
    onGoodsDetailClick(id) {
      this.$refs.refGoodsDetailView.showView({ editMode: "insert", id: id })
    },

    // 添加本页货品
    onBatchSelectThisPageClick() {
      this.$confirm("确定添加本页货品为分销货品吗?", "提示", { confirmButtonText: "确定", cancelButtonText: "取消", type: "warning" }).then(() => {
        var ids = []
        this.epDataList.forEach((value) => { ids.push(value.sup_goods_item_id) })
        var sup_goods_item_ids = ids.join(',')
        // console.log(goods_item_code_list); return
        apiV1ActionBatchSelectSupGoodsItem({
          fx_shop_id: AuthUtils.getCurrentShopId(),
          sup_goods_item_ids: sup_goods_item_ids
        }).then((res) => {
          // console.log(res.data)
          if (res.data.code == 0) {
            this.getPage()
            this.$message.success("操作成功")
          //   this.viewVisible = false
          } else {
            this.$message.error(res.data.message)
          }
        })

      }).catch(()=>{})
    },


    // ===================================================================================================

    checkImageUrl(url) {
      return (url == '' || url == null) ? '/static/images/default_image.png' : url
    },

  },
  watch: {},
  mounted() {
    this.$nextTick(() => {
      this.getPage()
    })
  }
}
</script>

<style scoped>
.m-card { width: 240px; margin: 10px;}
.m-card-label { color: rgb(153 153 153); line-height: 14px; font-size: 14px;}
.m-card-price {color: rgb(255, 106, 0);    font-size: 18px;    line-height: 18px;    font-weight: 800;}
.m-card-bottom {  margin-top: 8px;  line-height: 20px;  display: flex;  justify-content: space-between;  align-items: center;}
.m-main-image{ width:240px; height:240px; margin: 0; }
.m-main-image .el-image { padding: 4px;  max-width: 240px;  max-height: 240px;  width: 100%;  height: 240px; }
.m-main-image .image-slot { display: flex;  justify-content: center;  align-items: center;  width: 100%;  height: 100%;  background: #d4d7de;  color: var(--el-text-color-secondary);  font-size: 30px; }
.m-main-image .image-slot .el-icon { font-size: 30px; }
.m-select-text{ font-size: 12px; color: #f56c6c; font-weight: 700;}
</style>
