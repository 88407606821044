<template>
  <el-container class="ep-page-container">
    <el-header class="ep-page-header">
      <div class="ep-page-header-breadcrumb">
        <el-breadcrumb><el-breadcrumb-item>分销</el-breadcrumb-item><el-breadcrumb-item>分销信息</el-breadcrumb-item><el-breadcrumb-item>分销合作关系</el-breadcrumb-item></el-breadcrumb>
      </div>
      <div class="ep-page-header-filter"></div>
      <div class="ep-page-header-toolbar">
        <!-- <el-button type="primary" @click="onAddClick"><el-icon><Edit/></el-icon><span>添加合作关系</span></el-button> -->
      </div>
    </el-header>
    <!-- 其他窗体 START -->
    <!-- <FenxiaoRelationEditView ref="updateView" @submit="onEditSubmit" /> -->
    <!-- 其他窗体 END -->
    <el-main class="ep-page-main">
      <!-- 查询面板 START -->
      <FenxiaoRelationSearchPanel ref="searchView" @search="onSearched" @reset="onSearchReset"/>
      <!-- 查询面板 END -->

      <!-- 数据表格 START -->
      <div class="el-card" style="margin:10px 0 80px 0;">
        <!-- 数据表格-过滤标签栏 START -->
        <el-tabs type="card" v-model="dataTableFilter" @tab-change="onDataTableTabChange">
          <el-tab-pane label="全部" name="__ALL__"></el-tab-pane>
          <!-- <el-tab-pane label="未审核" name="0"></el-tab-pane> -->
          <!-- <el-tab-pane label="已审核" name="1"></el-tab-pane> -->
        </el-tabs>
        <!-- 数据表格-过滤标签栏 END -->

        <!-- 数据表格 START -->
        <!-- :height="esFixedTableHeight"  -->
        <div class="ep-table-panel-wrap">
          <el-table ref="epDataTable" fit stripe highlight-current-row size="small" v-loading="epIsLoading"
            :data="epDataList" :header-cell-style="{background:'#eef1f6',color:'#606266'}"
            @selection-change="onTableSelectionChange">
            
            <el-table-column type="selection" width="50" />

            <el-table-column prop="relation_id" label="#" width="100" />

            <!-- <el-table-column type="expand">
              <template #default="scope">
                <OrderExpandDetailView :row="scope.row"/>
              </template>
            </el-table-column> -->

            <el-table-column label="供应商" min-width="200">
              <template #default="scope">
                <div><span class="el-tag el-tag--plain el-tag--small" style="margin-right:4px;">供应商</span>{{ scope.row.sup_shop_name }}</div>
                <div class="ep-cell-subtitle">{{ scope.row.sup_merchant_name }}</div>
              </template>
            </el-table-column>

            <el-table-column prop="sup_shop_code" label="供应商编码" min-width="100" />

            <el-table-column label="分销商" min-width="200">
              <template #default="scope">
                <div><SourceCodeTag :value="scope.row.fx_source_code" /><span style="padding-left:5px;">{{ scope.row.fx_shop_name }}</span></div>
                <div class="ep-cell-subtitle">{{ scope.row.fx_merchant_name }}</div>
              </template>
            </el-table-column>

            <el-table-column prop="fx_shop_code" label="分销商编码" min-width="100" />

            <el-table-column label="合作关系" min-width="200">
              <template #default="scope">
                <template v-if="scope.row.relation_state == 1"><el-tag effect="dark">签约中</el-tag></template>
                <template v-else-if="scope.row.relation_state == 2"><el-tag type="success" effect="dark">合作中</el-tag></template>
                <template v-else-if="scope.row.relation_state == 3"><el-tag type="danger" effect="dark">已终止</el-tag></template>
                <template v-if="scope.row.relation_state == 0"><el-tag type="info" effect="dark">未合作</el-tag></template>
              </template>
            </el-table-column>

            <!-- <el-table-column label="下单时间" min-width="100">
              <template #default="scope">
                <span v-html="$$utils.toDataTimeHtml(scope.row.order_time)"></span>
              </template>
            </el-table-column> -->
            
            <el-table-column label="操作" min-width="150" fixed="right">
              <!-- <template #default="scope"> -->
                <!-- <span class="ep-action-button primary" @click="onUpdateClick(scope.row)">编辑</span> -->
                <!-- <span class="ep-action-line"/> -->
                <!-- <span class="ep-action-button danger" @click="onDeleteClick(scope.row)">删除</span> -->
              <!-- </template> -->
            </el-table-column>
          </el-table>
        </div>
        <!-- 数据表格 END -->
      </div>
      <!-- 数据表格面板 END -->

      <!-- 数据页面固定footer START -->
      <div class="ep-float-footer">
        <div class="ep-float-footer-toolbar">
          <el-button plain class="ep-button-select-all" @click="$refs.epDataTable.toggleAllSelection()">
            <span class="ep-button-select-all-text1">已选中 <span class="ep-button-select-all-text2">{{ epMultipleSelection.length }}</span> / <span class="ep-button-select-all-text2">{{ epDataList.length }}</span> 项</span>
          </el-button>
          <!-- <el-button type="primary" :disabled="getMultipleSelectionCount() == 0" @click="onCheckClick">审核</el-button>
          <el-button type="primary" :disabled="getMultipleSelectionCount() == 0" @click="onCancelClick">作废</el-button>
          <el-button type="primary" :disabled="getMultipleSelectionCount() == 0" @click="onUnancelClick">恢复</el-button> -->
        </div>
        <div class="ep-float-footer-pager">
          <ep-pagination :ep-page="this.epPage" :ep-total="this.epTotal" :ep-page-size="this.epPageSize" @pagination-change="onPaginationChange"/>
        </div>
      </div>
      <!-- 数据页面固定footer END -->

    </el-main>
    
  </el-container>
</template>

<script>
import DataTableMixin from '@/mixin/DataTableMixin.js'
import { Edit, ArrowDown, MoreFilled } from '@element-plus/icons-vue'
// import FenxiaoRelationEditView from '@/views/v1/fenxiao/fenxiao_relation/edit/FenxiaoRelationEditView'
import FenxiaoRelationSearchPanel from '@/views/v1/fenxiao/fenxiao_relation/list/FenxiaoRelationSearchPanel'
import SourceCodeTag from '@/components/v1/jxgocs/tags/SourceCodeTag'
import { apiV1GetFenxiaoRelationPage, apiV1DeleteFenxiaoRelation } from '@/api/fenxiao.js'
import AuthUtils from '@/utils/auth-utils.js'
import GlobalConfig from '@/config/index.js'


export default {
  mixins: [ DataTableMixin ],
  name: "order-list-page",
  components: {
    Edit, // Edit, ArrowDown,
    // FenxiaoRelationEditView,
    FenxiaoRelationSearchPanel,
    SourceCodeTag,
  },
  props: [],
  data() {
    return {
      // shop_id: '',
      // current_shop_id: '',
      // current_shop_id: '', //
      //
      dataTableFilter: '__ALL__',
    }
  },
  computed: {},
  watch: {},
  methods: {
    getFilter() {
      let data = {}
      if (AuthUtils.getCurrentShopId()) {
        data.fx_shop_id = AuthUtils.getCurrentShopId()
      }
      // if (this.dataTableFilter != '' && this.dataTableFilter != '__ALL__') {
      //   data.admin_check_state = this.dataTableFilter
      // }
      return data
    },
    getPage({reload = false} = {}) {
      let paginationData = this.getPagination(reload)
      let filterData = this.getFilter()
      let searchData = this.$refs.searchView.getSearchFilter() 
      let queryData = Object.assign(paginationData, filterData, searchData)

      this.epIsLoading = true

      apiV1GetFenxiaoRelationPage(queryData).then((res) => {
        this.epDataList = res.data.data
        this.setPaginationTotal(res.data.pagination.total)
        this.epIsLoading = false
      })
      
    },


    // ===================================================================================================


    // 回调触发 - 查询
    onSearched(data) {
      this.getPage({reload: true})
    },
    // 回调触发 - 查询重置
    onSearchReset() {
      this.getPage({reload: true})
    },
    // Change触发 - 数据表Tab
    onDataTableTabChange(name) {
      this.getPage({reload: true})
    },

    // 编辑提交
    onEditSubmit(result) {
      this.getPage()
      this.$message.success("操作成功")
    },
    // 点击触发 - 添加
    onAddClick(id) {
      this.$refs.updateView.showView({ editMode: "insert" })
    },
    // // 点击触发 - 查看店铺详情
    // onDetailClick(rowObj) {
    //   let newUrl = this.$router.resolve({ name: 'ShopDetailPage', params: { id: rowObj.shop_id } })
    //   window.open(newUrl.href, '_blank')
    // },
    // 点击触发 - 修改
    onUpdateClick(rowObj) {
      this.$refs.updateView.showView({ editMode: "update", id: rowObj.relation_id })
    },
    // 点击触发 - 删除
    onDeleteClick(rowObj) {
      this.resolveDeleteConfirm().then(() => {
        apiV1DeleteFenxiaoRelation({
          relation_id: rowObj.relation_id,
        }).then((res) => {
          this.getPage()
          this.$message.success("操作成功")
        })
      }).catch(()=>{})
    },


    // ===================================================================================================


  },
  mounted() {
    this.$$emitter.on('current-shop-changed', (shop_id) => {
      this.getPage()
    })

    this.$nextTick(() => {
      this.getPage()
    })
  }
}
</script>

<style scoped>
</style>
