<template>
  <el-drawer v-model="viewVisible" direction="rtl" size="1200px" class="ep-drawer" :append-to-body="true" :close-on-click-modal="false" @open="onViewOpen">
    <template #header><span>货品详情</span></template>
    <el-card>
      <el-row>
        <el-col :span="10">
          <el-image class="m-main-image" :src="checkImageUrl(item.main_image_url)" fit="contain">
            <template #error><div class="image-slot"><el-icon><IconPicture/></el-icon></div></template>
          </el-image>
        </el-col>
        <el-col :span="8">
          <h2 class="ep-overflow-text">{{ item.goods_item_name }}</h2>
          <!-- <div class="ep-cell-subtitle">品牌：{{ item.brand_name }}</div> -->
          <div class="m-card-bottom">
            <div class="m"><div class="m-card-label">供货价</div><div class="m-card-price">¥ {{ $$utils.toFixed2(item.cost_price) }}</div></div>
            <div class="m"><div class="m-card-label">建议零售价</div><div class="m-card-price">¥ {{ $$utils.toFixed2(item.retail_price) }}</div></div>
          </div>
          
          <el-divider />

          <div class="m-subtitle">货品类目：{{ category_code2Name(item.category_code) }}</div>

          <div class="m-subtitle">仓库信息：
            <!-- <span v-if="item.store_code != '__DEFAULT__'">[{{ item.store_code_code }}] {{ item.store_code_name }}</span><span v-else>默认仓库</span> -->
            <span v-if="item.store_code != '__DEFAULT__'">[{{ item.store_code_dcode }}] {{ item.store_code_dname }}</span><span v-else>默认仓库</span>
          </div>

          <div class="m-subtitle">库存信息：<span v-if="item.available_quantity">{{ item.available_quantity }}</span><span v-else>0</span></div>

          <template v-if="item.lifecycle_end_time">
            <div class="m-subtitle">效期到期日：<span v-html="$$utils.toDate(item.lifecycle_end_time)"></span>
              <el-alert title="仅供参考，仓库货物更新较快，以实收效期为准" type="warning" :closable="false" show-icon style="margin-top: 6px;"/>
            </div>
          </template>

        </el-col>

      </el-row>

      <el-descriptions title="货品基本信息" :column="3" border style="margin-top: 10px;">
        <!-- <el-descriptions-item
          label="Username"
          label-align="right"
          align="center"
          label-class-name="my-label"
          class-name="my-content"
          width="150px"
          >kooriookami</el-descriptions-item
        > -->
        <el-descriptions-item label="货品编码" label-align="left" align="center">{{ item.goods_item_code }}</el-descriptions-item>
        <el-descriptions-item label="品牌" label-align="left" align="center">{{ item.brand_name }}</el-descriptions-item>
        <el-descriptions-item label="条形码" label-align="left" align="center">{{ item.barcode }}</el-descriptions-item>
        
        <el-descriptions-item label="原产国" label-align="left" align="center">{{ item.origin_code_name }}</el-descriptions-item>
        <el-descriptions-item label="规格型号" label-align="left" align="center" :span="2">{{ item.goods_spec }}</el-descriptions-item>

        <el-descriptions-item label="成分" label-align="left" align="center" :span="3">{{ item.specification }}</el-descriptions-item>

       
        <!-- <el-descriptions-item label="Place" label-align="right" align="center"
          >Suzhou</el-descriptions-item
        >
        <el-descriptions-item label="Remarks" label-align="right" align="center">
          <el-tag size="small">School</el-tag>
        </el-descriptions-item>
        <el-descriptions-item label="Address" label-align="right" align="center"
          >No.1188, Wuzhong Avenue, Wuzhong District, Suzhou, Jiangsu
          Province</el-descriptions-item
        > -->
      </el-descriptions>

    </el-card>

    <template #footer>
      <el-button type="primary" :disabled="onSubmit_disable" @click="onSubmit"><el-icon><check/></el-icon><span>{{ onSubmit_title }}</span></el-button>
      <el-button type="default" @click="viewVisible = false"><el-icon><close/></el-icon><span>关闭</span></el-button>
    </template>
  </el-drawer>
</template>

<script>
import { Check, Close, Picture as IconPicture } from '@element-plus/icons-vue'
import { CategoryCodeModel } from '@/model/goods/CategoryCodeModel.js'
import { apiV1GetSupGoodsItemObject, apiV1ActionSelectSupGoodsItem } from '@/api/goods.js'
import AuthUtils from '@/utils/auth-utils.js'
import GlobalConfig from '@/config/index.js'

export default {
  name: 'goods-detail-view',
  components: {
    Check, Close, IconPicture
  },
  // props: [],
  emits: ['submit'],
  data() {
    return {
      editMode: 'insert',
      viewVisible: false,
      isLoading: false,

      merchant_id: '',
      formModel: {
        merchant_id: '',
        merchant_name: '',
        merchant_code: '',
        ref_key: '',
        merchant_state: 1,
        oms_service_version: 0,
      },

      item: [],
      main_image_url: '',

      onSubmit_title: '添加分销',
      onSubmit_disable: false,

      // 检验规则
      formRules: {
        // name: [{required: true, message: '请输入中文名', trigger: 'blur' }, {max: 20, message: '中文名长度不超过 20 个字符', trigger: 'blur'}],
        // staff_code: [{required: true, message: '员工编码', trigger: 'blur'}],
        // goods_name: [{required: true, message: '员工编码', trigger: 'blur'}],
      },
      // 拷贝规则
      cloneFields: [
        'merchant_name', 'merchant_code', 'ref_key', {key: 'merchant_state', default: 1}, {key: 'oms_service_version', default: 0}, 
      ],
    }
  },
  computed: {
  },
  methods: {
    // 显示view
    showView({ editMode, id } = {}) {
      this.editMode = editMode || 'insert'
      this.sup_goods_item_id = id
      this.viewVisible = true
    },
    
    // 当View打开时
    onViewOpen() {
      this.onSubmit_title = '添加分销'; this.onSubmit_disable = false
      
      apiV1GetSupGoodsItemObject({
        sup_goods_item_id: this.sup_goods_item_id,
        fx_shop_id: AuthUtils.getCurrentShopId(),
        is_show_selected_state : 1,
      }).then((res) => {
        this.item = res.data.data
        // this.$$utils.cloneObject(res.data.data, this.cloneFields)
        // loadingInstance.close()
        if (res.data.data.selected_state == 1) {
          this.onSubmit_title = '已添加'; this.onSubmit_disable = true
        }
      })
    },
    // 点击提交按钮
    onSubmit() {
      apiV1ActionSelectSupGoodsItem({
        fx_shop_id: AuthUtils.getCurrentShopId(),
        sup_shop_id: this.item.sup_shop_id,
        goods_item_code: this.item.goods_item_code
      }).then((res) => {
        // console.log(res.data)
        if (res.data.code == 0) {
          this.$emit('submit')
          this.$message.success(res.data.message)
          this.viewVisible = false
        } else {
          this.$message.error(res.data.message)
        }
      })
    },


    checkImageUrl(url) {
      return (url == '' || url == null) ? '/static/images/default_image.png' : url
    },

    category_code2Name(code) {
      return CategoryCodeModel.code2Name(code)
    }


  },
  mounted() {
    // this.sup_goods_item_id = 
  }
};
</script>

<style scoped>
.m-card { width: 400px; margin: 10px;}
.m-card-label { color: rgb(153 153 153); line-height: 14px; font-size: 14px;}
.m-card-price {color: rgb(255, 106, 0);    font-size: 18px;    line-height: 18px;    font-weight: 800;}
.m-card-bottom {  margin-top: 8px;  line-height: 20px;  display: flex;  justify-content: space-between;  align-items: center;}
.m-main-image{ width:400px; height:400px; margin: 0; }
.m-main-image .el-image { padding: 4px;  max-width: 400px;  max-height: 400px;  width: 100%;  height: 400px; }
.m-main-image .image-slot { display: flex;  justify-content: center;  align-items: center;  width: 100%;  height: 100%;  background: #d4d7de;  color: var(--el-text-color-secondary);  font-size: 30px; }
.m-main-image .image-slot .el-icon { font-size: 30px; }

.m-subtitle{ color: rgb(153 153 153); line-height: 16px; font-size: 16px; padding: 20px 0; }
</style>
